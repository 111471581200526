<template>
    <el-container style="height: 100%;">
      <el-aside :style="{ width: elAsideWidth + 'px' }">
        <el-row style="height: 100%;">
          <el-col :span="24">
            <el-menu
              style="height: 100%; border: none;"
              @open="handleOpen"
              @close="handleClose"
              :unique-opened="true"
              :collapse="collapse"
              background-color="#303133"
              text-color="#fff"
              active-text-color="#6a66ff"
              :default-openeds="defaultOpened"
              :collapse-transition="false"
            >
              <el-container>
                <el-main style="display: flex; font-size: 15px;">
                  <img
                    src="@/assets/logo.png"
                    style="margin-left: -10px; height: 50px; width: 50px;"
                    v-if="!collapse"
                  />
                  <p style="color: #fff;">鑫易付代理商平台</p>
                </el-main>
              </el-container>
              <template v-for="(route, pKey) in router" :key="route.name">
                <template
                  v-if="route.name != 'login' && route.name != '404' && route.name != 'resetPassword'"
                >
                  <el-sub-menu :index="pKey + ''">
                    <template #title>
                      <el-icon>
                        <span class="iconfont" :class="route.meta.icon" style="color: #ccc"></span>
                      </el-icon>
                      <span>{{ route.name }}</span>
                    </template>
                    <template v-for="(child, cKey) in route.children" :key="child.name">
                      <el-menu-item :index="pKey + '-' + cKey" @click="selectItemMenu(pKey)">
                        <router-link :to="child.path" style="text-decoration: none;">
                          <span style="color: #fff; width: 200px; display: block; user-select: none;">
                            {{ child.name }}
                          </span>
                        </router-link>
                      </el-menu-item>
                    </template>
                  </el-sub-menu>
                </template>
              </template>
            </el-menu>
          </el-col>
        </el-row>
      </el-aside>
      <el-container>
        <el-header class="header">
          <el-row style="margin-top: 10px;">
            <el-col :span="1">
              <span
                style="color: #fff; font-size: 25px; height: 40px; line-height: 48px; cursor: pointer;"
                @click="isShowMenu"
              >
                <el-icon v-if="collapse"><Expand /></el-icon>
                <el-icon v-else><Fold /></el-icon>
              </span>
            </el-col>
            <el-col :span="11">
              <el-breadcrumb separator=">" style="height: 40px; line-height: 40px;">
                <el-breadcrumb-item>
                  <span class="text-color">{{ breadcrumbNames[0] }}</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item>
                  <span class="text-color">{{ breadcrumbNames[1] }}</span>
                </el-breadcrumb-item>
              </el-breadcrumb>
            </el-col>
            <el-col :span="12" style="text-align: right;">
              <el-dropdown style="margin-right: 15px;">
                <span class="el-dropdown-link" style="height: 40px; line-height: 40px;">
                  <span style="color: #fff;">
                    {{ agentName }}
                    <el-icon><ArrowDown /></el-icon>
                  </span>
                </span>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item @click="logout">退出</el-dropdown-item>
                    <el-dropdown-item @click="openDialog">修改密码</el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </el-col>
          </el-row>
        </el-header>
        <el-main>
          <el-tabs
            class="tabs"
            type="card"
            :model-value="isActive"
            closable
            style="margin: -20px"
            @tab-remove="removeTab"
            @tab-click="clickTab"
          >
            <template v-for="(tab, index) in tabsList" :key="tab.name">
              <el-tab-pane :label="tab.title" :name="index + '-' + tab.path" style="padding: 10px 20px;">
                <el-config-provider :locale="locale">
                  <router-view :name="tab.name" v-slot="{ Component, route }">
                    <keep-alive :max="20">
                      <component :is="Component" :key="route.fullPath" />
                    </keep-alive>
                  </router-view>
                </el-config-provider>
              </el-tab-pane>
            </template>
          </el-tabs>
        </el-main>
      </el-container>
    </el-container>
    <resetPassword :open="dialogVisible" @update:open="handleUpdateOpen" :showStoreId="false"></resetPassword>
  </template>
  
  <script>
  import { ArrowDown, Expand, Fold } from '@element-plus/icons-vue';
  import pathTools from '@/tools/pathTools';
  import { store } from '@/common/store';
  import storeTools from '@/tools/storeTools';
  import logoImg from '@/assets/logo.png';
  import zhCn from 'element-plus/dist/locale/zh-cn.mjs';
  import resetPassword from '../../components/reSetPassword/index.vue';
  
  export default {
    components: {
      ArrowDown,
      Expand,
      Fold,
      resetPassword,
    },
    data() {
      return {
        router: '', // 路由
        defaultOpened: [], // 路由默认开启的导航栏
        breadcrumbNames: [], // 面包屑
        tabsList: [], // 选项卡标签列表
        isActive: '', // 显示的标签
        logoUrl: logoImg, // 平台log
        screenWidth: null, // 屏幕宽度
        collapse: false, // 是否展开或隐藏导航栏
        isNone: 'block', // 是否隐藏或显示log文案
        elAsideWidth: 200, // 导航栏宽度
        agentName: '', // 代理商会员名称
        locale: zhCn,
        dialogVisible: false,
      };
    },
    watch: {
      screenWidth(newValue) {
        // 当屏幕宽度小于等于800时，隐藏菜单栏
        if (newValue <= 800) {
          this.showMenu(true);
        } else {
          this.showMenu(false);
        }
      },
    },
    beforeMount() {
      this.loadPath();
      this.setDefaultOpened();
      this.setBreadcrumbNames();
      this.setTabsList();
      this.setIsActive();
    },
    mounted() {
      this.screenWidth = document.body.clientWidth;
      window.onresize = () => {
        // 屏幕尺寸变化时更新屏幕宽度
        this.screenWidth = document.body.clientWidth;
      };
      this.updateAgentInfo();
    },
    updated() {
      this.setTabsList();
      this.setIsActive();
      this.setBreadcrumbNames();
    },
    beforeUnmount() {
      localStorage.clear();
    },
    methods: {
      /**
       * 选择菜单项事件
       */
      selectItemMenu(pKey) {
        store.setMenuIndex(pKey);
        this.setBreadcrumbNames();
        this.setTabsList();
        this.setIsActive();
      },
      /**
       * 加载路由
       */
      loadPath() {
        this.router = pathTools.loadPath();
      },
      /**
       * 菜单打开事件
       */
      handleOpen(key) {
        store.setMenuIndex(key);
      },
      /**
       * 菜单关闭事件
       */
      handleClose(key) {
        store.setMenuIndex(key);
      },
      /**
       * 设置默认展开的路由
       */
      setDefaultOpened() {
        this.defaultOpened = [store.menuIndex];
      },
      /**
       * 设置面包屑文本
       */
      setBreadcrumbNames() {
        this.breadcrumbNames = store.getBreadcrumbNames();
      },
      /**
       * 设置标签页
       */
      setTabsList() {
        this.tabsList = storeTools.getTabs();
      },
      /**
       * 删除标签页
       */
      removeTab(index) {
        if (this.tabsList.length <= 1) return;
  
        const indexArr = index.split('-');
        let i = Number(indexArr[0]);
  
        this.tabsList.splice(i, 1);
        storeTools.removeTab(i);
  
        const tabs = storeTools.getTabs();
        const nextTab = tabs[i - 1] || tabs[i] || tabs[i + 1];
  
        if (index == this.isActive) {
          this.$router.replace({ path: nextTab.path });
        }
  
        const activeArr = this.isActive.split('-');
        let ai = Number(activeArr[0]);
  
        if (i < ai) {
          this.isActive = (ai - 1) + '-' + activeArr[1];
        }
      },
      /**
       * 点击标签页事件
       */
      clickTab(pane) {
        this.handleClose();
        const path = pane.props.name.split('-')[1];
        this.$router.replace({ path });
      },
      /**
       * 设置当前活动的标签页
       */
      setIsActive() {
        this.isActive = store.getIsActive();
      },
      /**
       * 显示或隐藏菜单
       */
      isShowMenu() {
        this.showMenu(!this.collapse);
      },
      /**
       * 展开或隐藏菜单
       * @param {boolean} collapse true 隐藏,false 展开
       */
      showMenu(collapse) {
        if (collapse) {
          this.elAsideWidth = 64;
          this.isNone = 'none';
        } else {
          this.elAsideWidth = 200;
          this.isNone = 'block';
        }
        this.collapse = collapse;
      },
      /**
       * 更新代理商信息
       */
      updateAgentInfo() {
        const agentUser = JSON.parse(sessionStorage.getItem('agent_user'));
        this.agentName = agentUser.company;
      },
      /**
       * 退出
       */
      logout() {
        sessionStorage.clear();
        this.$router.push({ path: '/login' });
        window.location.reload();
      },
      /**
       * 打开修改密码对话框
       */
      openDialog() {
        this.dialogVisible = true;
      },
      /**
       * 处理对话框状态更新
       */
      handleUpdateOpen(newValue) {
        this.dialogVisible = newValue;
      },
    },
  };
  </script>
  
  <style lang="less">
  html,
  body,
  #app {
    margin: 0;
    height: 100%;
  }
  
  .header {
    background-color: #3d3d41;
    color: #fff;
  }
  
  .footer {
    background-color: #e5e5f6;
    color: #1f2430;
    text-align: center;
    line-height: 60px;
  }
  
  .text-color {
    color: #fff;
  }
  </style>
  